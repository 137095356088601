import { ref, reactive } from "vue"
import c3api from "../c3api"
import { useRouter } from "vue-router"
import { useToast } from "vue-toastification"
import { unwrapReactive } from "../utils/unwrapReactive"
import { useLoadingStore } from "../stores/loading"

export default function useSupportTickets() {
  const supportTicketsCountByStatus = ref(null);
  const supportTickets = ref(null)
  const supportTicket = ref(null)
  const supportTicketComments = ref(null)
  const supportTicketsLoading = ref(false)
  const supportTicketAdmins = ref(null)
  const supportTicketUsers = ref([])
  const defaultUserLocalState = reactive({})
  const supportTicketAssignments = ref([])
  const toast = useToast()
  const defaultLocalState = reactive({})
  const router = useRouter()
  const loadingStore = useLoadingStore()
  const uploadInProgress = ref(false)

  const supportTicketRoles = ref(["user", "tenant_admin", "superadmin"])

  const ticketStatusMap = {
    awaitingResponse: "awaiting_response",
    closed: "closed",
    inPlanning: "in_planning",
    inProgress: "in_progress",
    investigating: "investigating",
    onHold: "on_hold",
    open: "open",
    resolved: "resolved",
  }

  const ticketStatuses = [
    { title: "Open", value: ticketStatusMap.open },
    { title: "Investigating", value: ticketStatusMap.investigating },
    { title: "In progress", value: ticketStatusMap.inProgress },
    { title: "In planning", value: ticketStatusMap.inPlanning },
    { title: "On hold", value: ticketStatusMap.onHold },
    { title: "Awaiting response", value: ticketStatusMap.awaitingResponse },
    { title: "Closed", value: ticketStatusMap.closed },
    { title: "Resolved", value: ticketStatusMap.resolved },
  ]
  const ticketPriorities = [
    { title: "Low", value: "low" },
    { title: "Normal", value: "normal" },
    { title: "High", value: "high" },
    { title: "Urgent", value: "urgent" },
  ]
  const ticketCategories = [
    { title: "Triage", value: "triage" },
    { title: "IT Problem", value: "it_problem" },
    { title: "IT Request", value: "it_request" },
    { title: "IT Task", value: "it_task" },
    { title: "Connect Problem", value: "connect_problem" },
    { title: "Connect Request", value: "connect_request" },
    { title: "Connect Task", value: "connect_task" },
    { title: "Feedback", value: "feedback" },
    { title: "Other", value: "other" },
  ]

  const getSupportTicketsCountByStatus = () => {
    c3api
      .get(`/stats/support_ticket_count_by_status`)
      .then((response) => {
        supportTicketsCountByStatus.value = response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchSupportTickets = async (localState = defaultLocalState) => {
    supportTicketsLoading.value = true
    try {
      const params = unwrapReactive(localState)
      const response = await c3api.get("/support_tickets", { params })
      supportTickets.value = response.data
    } catch (error) {
      console.log(error)
      toast.error(error.response?.data?.data.join(". "))
    } finally {
      supportTicketsLoading.value = false
    }
  }

  const createSupportTickets = async (ticketTitle, content, plainText, ticketPriority) => {
    loadingStore.state(true)

    const params = {
      title: ticketTitle,
      content: content,
      content_text: plainText,
      priority: ticketPriority,
    }

    try {
      await c3api.post("/support_tickets", params)
      toast.success("Ticket created successfully.")
      router.push("/support-tickets")
    } catch (error) {
      console.error(error)
      toast.error(error.response.data.data.join(". "))
    } finally {
      loadingStore.state(false)
    }
  }

  const fetchSupportTicket = async (id, localState = defaultLocalState) => {
    try {
      const params = unwrapReactive(localState)
      const response = await c3api.get(`/support_tickets/${id}`, { params })
      supportTicket.value = response.data.data
      return supportTicket.value
    } catch (error) {
      toast.error(error.response?.data?.data.join(". "))
      router.push("/404")
    }
  }

  const fetchSupportTicketAssignments = async (id, localState = defaultLocalState) => {
    try {
      const params = unwrapReactive(localState)
      const response = await c3api.get(`/support_tickets/${id}/assignments`, {
        params,
      })
      return (supportTicketAssignments.value = response.data.data)
    } catch (error) {
      console.log(error)
      toast.error(error.response?.data?.data.join(". "))
    }
  }

  const assignSupportTicket = async (id, assignee_id) => {
    try {
      const response = await c3api.post(`/support_tickets/${id}/assignments`, {
        assigned_to_id: assignee_id,
      })
      toast.success(`Ticket assigned to ${response.data.data.assigned_to.name} successfully`)
      fetchSupportTicket(id)
      fetchSupportTicketAssignments(id)
    } catch (error) {
      console.log(error)
      toast.error(error.response?.data?.data.join(". "))
    }
  }

  const unassignSupportTicket = async (assignment_id, ticket_id) => {
    try {
      const response = await c3api.delete(`/support_ticket_assignments/${assignment_id}`)
      toast.success(`Ticket unassigned successfully`)
      fetchSupportTicket(ticket_id)
      fetchSupportTicketAssignments(ticket_id)
    } catch (error) {
      console.log(error)
      toast.error(error.response?.data?.data.join(". "))
    }
  }

  const changeCategory = async (id, newCategory) => {
    try {
      const response = await c3api.patch(`/support_tickets/${id}/category`, {
        category: newCategory,
      })
      return (supportTicket.value = response.data.data)
    } catch (error) {
      toast.error(error.response.data.data.join(". "))
    }
  }

  const changePriority = async (id, newPriority) => {
    try {
      const response = await c3api.patch(`/support_tickets/${id}/priority`, {
        priority: newPriority,
      })
      return (supportTicket.value = response.data.data)
    } catch (error) {
      toast.error(error.response.data.data.join(". "))
    }
  }

  const changeStatus = async (id, newStatus) => {
    try {
      const response = await c3api.patch(`/support_tickets/${id}/status`, {
        status: newStatus,
      })
      toast.success(`Ticket status changed to ${newStatus}`)
      return (supportTicket.value = response.data.data)
    } catch (error) {
      toast.error(error.response.data.data.join(". "))
    }
  }

  const deleteSupportTicket = async (ticket) => {
    const confirmation = confirm(`Are you sure you want to delete ticket "${ticket.title}"?`)
    if (confirmation) {
      loadingStore.state(true)
      try {
        const response = await c3api.delete(`/support_tickets/${ticket.id}`)
        toast.success(`Ticket "${ticket.title}" deleted successfully`)
        router.push("/support-tickets")
        fetchSupportTickets()
      } catch (error) {
        console.error(error)
        toast.error(error.response.data.data.join(". "))
      }
      loadingStore.state(false)
    }
  }

  const deleteSupportTicketComment = async (comment) => {
    const confirmation = confirm("Are you sure you want to delete this comment?")
    if (confirmation) {
      try {
        const response = await c3api.delete(`/support_ticket_comments/${comment.id}`)
        toast.success(`Comment ${comment.id} deleted successfully`)
        fetchSupportTicketComments(comment.support_ticket_id)
        return response.data
      } catch (error) {
        toast.error(error.response.data.data.join(". "))
      }
    }
  }

  const fetchSupportTicketComments = async (id, params = { sort: "+created_at", page: 1, per_page: 20 }) => {
    try {
      const response = await c3api.get(`/support_tickets/${id}/comments`, {
        params,
      });
      supportTicketComments.value = response.data;
      return response.data;
    } catch (error) {
      toast.error(error.response.data.data.join(". "));
    }
  };

  const addComment = async (id, commentContent, newStatus) => {
    loadingStore.state(true)
    try {
      const response = await c3api.post(`/support_tickets/${id}/comments`, {
        content: commentContent,
        ticket_status: newStatus,
      })
      fetchSupportTicket(id)
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.data.join(". "))
    } finally {
      loadingStore.state(false)
    }
  }

  const uploadImage = async (editor) => {
    let input = document.createElement("input")
    input.type = "file"
    input.onchange = async (_) => {
      let files = Array.from(input.files)
      return upload(files[0], editor)
    }
    input.click()
  }

  const upload = async (file, editor) => {
    uploadInProgress.value = true
    const formData = new FormData()
    formData.append("file", file)
    let response = null
    try {
      response = await c3api.post("/support_tickets/upload_image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      const image = response.data.data
      toast.success("Image uploaded successfully.")
      if (image) {
        editor.chain().focus().setImage({ src: image.url }).run()
      }
    } catch (err) {
      console.error(err)
      uploadInProgress.value = false
      toast.error(err.response.data.data.join(". "))
    }
    uploadInProgress.value = false
    return response.data.url
  }

  const fetchSupportTicketAdmins = async () => {
    try {
      const response = await c3api.get("/support_tickets/admins")
      supportTicketAdmins.value = response.data
      return response.data
    } catch (error) {
      console.error(error)
      toast.error(error.response.data.data.join(". "))
    }
  }

  const updateSupportTicketAdmins = async (user, support_ticket_admin, admin_type) => {
    const params = {
      id: user.id,
      support_ticket_admin: support_ticket_admin,
      admin_type: admin_type,
    }
    try {
      const response = await c3api.patch("/support_tickets/admins", params)
      if (admin_type == undefined) {
        if (user.permissions.support_ticket_role == "superadmin") {
          admin_type = "superadmin"
        } else if (user.permissions.support_ticket_role == "tenant_admin") {
          admin_type = "tenant_admin"
        }
      }
      toast.success(
        `${user.name} ${
          support_ticket_admin ? "added" : "removed"
        } as a ${admin_type} ticket admin`,
      )
      return response.data
    } catch (error) {
      console.error(error)
      toast.error(error.response.data.data.join(". "))
    }
  }

  const fetchSupportTicketUsers = async (localState = defaultUserLocalState) => {
    try {
      const params = unwrapReactive(localState)
      const response = await c3api.get("/support_tickets/users", { params })
      return (supportTicketUsers.value = response.data)
    } catch (error) {
      toast.error(error.response?.data?.data.join(". "))
    }
  }

  function updatePage(newPage) {
    router.push({ query: { page: newPage } })
  }

  return {
    getSupportTicketsCountByStatus,
    supportTicketsCountByStatus,
    supportTickets,
    supportTicketComments,
    supportTicketAssignments,
    supportTicket,
    changePriority,
    changeCategory,
    fetchSupportTickets,
    createSupportTickets,
    fetchSupportTicket,
    deleteSupportTicket,
    fetchSupportTicketComments,
    deleteSupportTicketComment,
    changeStatus,
    assignSupportTicket,
    fetchSupportTicketAssignments,
    addComment,
    uploadImage,
    upload,
    uploadInProgress,
    ticketStatuses,
    ticketStatusMap,
    ticketPriorities,
    ticketCategories,
    fetchSupportTicketAdmins,
    updateSupportTicketAdmins,
    supportTicketAdmins,
    unassignSupportTicket,
    fetchSupportTicketUsers,
    supportTicketUsers,
    supportTicketRoles,
    updatePage,
    supportTicketsLoading,
  }
}
